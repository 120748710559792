import React from "react";
import TreatmetPage from "./Pages/TreatmentPage";
function Treatmet() {
  return (
    <div>
      <TreatmetPage />
    </div>
  );
}

export default Treatmet;
