import React, { useState } from "react";
import logo from "../img/logo.webp";
import { FaPhone, FaLocationDot } from "react-icons/fa6";
import { TypeAnimation } from "react-type-animation";
import mexico from "../img/Mexico.png";
import usa from "../img/USA.png";
import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
function ContactPage() {
  const [language, setLanguage] = useState("en"); // Estado para el idioma actual

  const toggleLanguage = () => {
    // Función para cambiar el idioma
    setLanguage(language === "es" ? "en" : "es");
  };

  const [openFooterTermsOfService, setFooterOpen] = React.useState(false);
  const handleFooterTermsOfService = () =>
    setFooterOpen(!openFooterTermsOfService);

  const [openFooterPrivaPolicy, setFooterPrivacyOpen] = React.useState(false);
  const handleFooterPrivacyPolicyOpen = () =>
    setFooterPrivacyOpen(!openFooterPrivaPolicy);

  const [openFooterAbout, setFooterAboutOpen] = React.useState(false);
  const handleFooterAbout = () => setFooterAboutOpen(!openFooterAbout);

  const text = {
    es: `En nuestra oficina dental, entendemos que tu salud oral es una
  parte importante de tu bienestar general. Es por eso que tenemos un equipo
  de dentistas experimentados y especialistas en cuidado de la salud oral que están
  dedicados a proporcionar el más alto nivel de atención profesional para
  tu boca. Ya sea que necesites alivio del dolor dental en el nervio,
  quieras mejorar la estética de tu sonrisa, necesites restaurar
  la funcionalidad a tu boca, o estés considerando implantes dentales, tenemos
  la experiencia para ayudar. Nuestra instalación de última generación está
  equipada con la última tecnología y técnicas para asegurar que
  tu visita sea cómoda y eficiente. Ofrecemos una amplia gama de
  servicios para satisfacer todas tus necesidades dentales. Contáctanos hoy para
  programar una cita y dar el primer paso hacia una sonrisa más saludable,
  más segura.`,
    en: `At our dental office, we understand that your oral health is an
  important part of your overall well-being. That's why we have a team
  of experienced dentists and specialists in oral health care who are
  dedicated to providing the highest level of professional care for
  your mouth. Whether you need relief from dental pain in the nerve,
  want to improve the aesthetics of your smile, need to restore
  functionality to your mouth, or are considering dental implants, we
  have the expertise to help. Our state-of-the-art facility is
  equipped with the latest technology and techniques to ensure that
  your visit is comfortable and efficient. We offer a wide range of
  services to meet all of your dental needs. Contact us today to
  schedule an appointment and take the first step toward a healthier,
  more confident smile.`,
  };
  return (
    <main>
      <nav>
        <div className="flex justify-between md:mx-44 items-center px-8 py-4 text-black">
          <a href="/">
            <img src={logo} alt="logo" className="w-12" />
          </a>
          <ul className="flex gap-4">
            <li>
              <a href="/" className="hover:text-green-500 text-xl">
                {language === "es" ? "Regresar al inicio" : "Back to home"}
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <section className="m-12 sm:mx-48  md:mx-52 md:my-24">
        <div className="flex justify-start items-center gap-4">
          <img
            src={language === "es" ? mexico : usa}
            alt="language"
            className="w-16"
          />
          <button
            onClick={toggleLanguage}
            className=" bg-green-500 hover:bg-green-700 border rounded-lg px-4 text-white"
          >
            {language === "es" ? "Español" : "English"}
          </button>
        </div>
        <div className="flex items-center py-4 justify-start gap-2">
          <a href="/">
            <img src={logo} alt="logo" className="w-12 h-12" />
          </a>
          <h1 className="text-green-500 text-4xl">
            <TypeAnimation
              cursor={true}
              sequence={["Reforma Dental", 1000]}
              wrapper="span"
              speed={10}
            />
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 py-8 gap-8 border-t border-green-200">
          <div className="font-poppins font-light animate-fade-right animate-duration-[2000ms] animate-normal">
            <h1 className="font-poppins text-3xl text-green-500 font-medium">
              {language === "es"
                ? "Informacion de contacto"
                : "Contact information"}
            </h1>
            <span className="flex justify-start items-start text-justify">
              {text[language]}
            </span>
            <a
              href="https://api.whatsapp.com/send?phone=+16195861436&text=Hola,%20visite%20su%20pagina%20y%20quisiera%20conocer%20más%20sobre%20sus%20servicios%20dentales"
              target="_blank"
              rel="noreferrer"
            >
              <button className="border rounded-xl px-4 py-2 bg-green-400 font-medium hover:bg-green-600 text-white">
                {language === "es" ? "Contáctanos" : "Contact us"}
              </button>
            </a>
          </div>
          <div className="font-poppins animate-fade-left animate-duration-[2000ms] animate-normal">
            <h1 className="font-poppins text-3xl text-green-500 font-medium">
              {language === "es" ? "Ubicación" : "Location"}
            </h1>
            <div className="flex flex-col">
              <div>
                <FaLocationDot className="inline-block mr-2 text-green-500" />
                <span>
                  8169-206 Ignacio Zaragoza, Zona Centro, Tijuana, Baja
                  California, México.
                </span>
              </div>
              <div>
                <FaPhone className="inline-block mr-2 text-green-500" />
                <span>+1 619-586-1436</span>
              </div>
            </div>
            <div
              className="relative h-0 mt-4"
              style={{ paddingBottom: "56.25%" }}
            >
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3364.008303115534!2d-117.03663105457193!3d32.52592519987922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d9499b13b355e9%3A0x33f0692b196212e5!2sReforma%20Dental!5e0!3m2!1ses!2smx!4v1696869140483!5m2!1ses!2smx"
                title="Ubicación de tu empresa en Google Maps"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <footer className="border-t border-green-200 font-poppins font-medium">
          <div className="flex md:flex-row flex-col justify-between items-center py-8 ">
            <img src={logo} alt="logo-ct" className="w-10 mb-8 md:mb-0" />
            <div className="flex md:flex-row flex-col gap-8">
              <button className="hover:text-green-700 text-xl">
                <a href="/">{language === "es" ? "Inicio" : "Home"}</a>
              </button>
              <button
                className="text-xl hover:text-green-500"
                onClick={handleFooterAbout}
              >
                {language === "es" ? "Sobre nosotros" : "About us"}
              </button>
              <button
                className="text-xl hover:text-green-500"
                onClick={handleFooterTermsOfService}
              >
                {language === "es"
                  ? "Terminos de servicio"
                  : "Terms of Service"}
              </button>
              <button
                className="text-xl hover:text-green-500"
                onClick={handleFooterPrivacyPolicyOpen}
              >
                {language === "es"
                  ? "Politicas de Privacidad"
                  : "Privacy Policy"}
              </button>
            </div>
          </div>
          <p className="text-green-500 text-2xl text-center">
            {language === "es"
              ? "@ 2022 Reforma Dental. Todos los derechos reservados."
              : "© 2022 Reforma Dental. All rights reserved."}
          </p>
        </footer>
        <Dialog
          open={openFooterTermsOfService}
          handler={handleFooterTermsOfService}
        >
          <DialogHeader className="flex justify-center">
            Terms of Service
          </DialogHeader>
          <DialogBody divider className="h-[20rem] overflow-scroll">
            <Typography className="font-normal">
              Once you have selected the service you want, you must reserve the
              service during the times available on the platform.
              <br /> <br />
              The agent services reserve the space in the office at the times as
              selected at the time of your selection, our system only allows
              modifications to be made within the page.
              <br /> <br />
              Once the reservation is completed, you will receive an email from
              us, within a maximum of 2 hours with the reservation information.
              <br /> <br />
              If you make a deposit, once the money is reflected in our account,
              a confirmation call will be made up to 24 hours before the
              reservation confirming the order by email. Confirmation of the
              purchase deposit must be confirmed by the administrative area. In
              no case can the space be scheduled if it is not released by the
              corresponding department. <br />
              <br />
              <h1 className="font-bold text">Reservation Policy. </h1>
              Any reservation made on the page goes directly to our agenda at
              the reception. Appointments are confirmed by telephone and must be
              attended by the patient in the period of 48 hours to 24 hours
              before the appointment. Unconfirmed appointments may be considered
              canceled by the patient. Cancellation with refund of the
              appointment may be made as long as the notification is 72 hours
              before the scheduled date. If it is within the 72 hour period,
              only the appointment can be rescheduled. Any situation that arises
              due to exchange or return must be notified by email to
              mydentist@dentalreforma.com sending a description of the problem
              or reason for the cancellation.
            </Typography>
          </DialogBody>
          <DialogFooter className="space-x-2">
            <Button
              variant="outlined"
              color="red"
              onClick={handleFooterTermsOfService}
            >
              close
            </Button>
          </DialogFooter>
        </Dialog>
        <Dialog
          open={openFooterPrivaPolicy}
          handler={handleFooterPrivacyPolicyOpen}
        >
          <DialogHeader className="flex justify-center">
            Privacy Policy
          </DialogHeader>
          <DialogBody divider className="h-[20rem] overflow-scroll">
            <Typography className="font-normal">
              At Reformadental.com, accessible from
              https://www.reformadental.com, one of our main priorities is the
              privacy of our visitors. This Privacy Policy document contains the
              types of information that are collected and recorded by Reforma
              Dental and how we use it.
              <br />
              Communicate with you, either directly or through one of our
              partners, including customer service, to provide you with updates
              and other information related to the site, and for marketing and
              promotional purposes. Send you emails Find and prevent fraud
              <br />
              <br />
              Log files Reforma Dental.com follows a standard procedure of using
              log files. These files record visitors when they visit the
              websites. All hosting companies do this and part of the analysis
              of hosting services. The information collected by log files
              includes Internet Protocol (IP) addresses, browser type, Internet
              Service Provider (ISP), date and time stamp, referring/exit pages,
              and possibly the page number. of clicks. These are not linked to
              any personally identifiable information. The purpose of the
              information is to analyze trends, administer the site, track
              users' movement around the website, and gather demographic
              information. Our Privacy Policy was created with the help of the
              Privacy Policy Generator and Disclaimer Generator.
              <br />
              <br />
              Cookies and Web Beacons Like any other website, Reforma Dental.com
              uses "cookies". These cookies are used to store information,
              including visitors' preferences, and the pages of the website that
              the visitor accessed or visited. The information is used to
              optimize users' experience by customizing our website content
              based on visitors' browser type and/or other information.
              <br />
              <br />
              For more general information about cookies, read "What are
              cookies?
              <br />
              <br />
              Google DoubleClick DART Cookie Google is one of the third party
              providers of our site. It also uses cookies, known as DART
              cookies, to serve ads to our site visitors based on their visit to
              www.website.com and other sites on the Internet. However, visitors
              may choose to decline the use of DART cookies by visiting the
              Google Ad and Content Network Privacy Policy at the following URL
              – https://policies.google.com/technologies/ads
              <br />
              <br />
              Our advertising partners Some of the advertisers on our site may
              use cookies and web beacons. Our advertising partners are listed
              below. Each of our advertising partners has their own privacy
              policy for their user data policies. For easy access, we have
              created a hyperlink to their privacy policies below.
              <br />
              <br />
              Google https://policies.google.com/technologies/ads
              <br />
              <br />
              Privacy policies of advertising partners You may consult this list
              to find the privacy policy for each of the advertising partners of
              Reforma Dental.com
              <br />
              <br />
              Third-party ad servers or ad networks use technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on Reforma
              Dental.com, which are sent directly to users' browser. They
              automatically receive your IP address when this occurs. These
              technologies are used to measure the effectiveness of their
              advertising campaigns and/or to personalize the advertising
              content you see on the websites you visit.
              <br />
              <br />
              Please note that Reforma Dental has no access to or control over
              these cookies that are used by third-party advertisers.
              <br />
              <br />
              Third Party Privacy Policies The Reforma Dental privacy policy
              presented applies to other advertisers or websites. Therefore, we
              advise you to consult the respective privacy policies of these
              third-party ad servers for more detailed information. It may
              include its practices and instructions about how to opt out of
              certain options.
            </Typography>
          </DialogBody>
          <DialogFooter className="space-x-2">
            <Button
              variant="outlined"
              color="red"
              onClick={handleFooterPrivacyPolicyOpen}
            >
              close
            </Button>
          </DialogFooter>
        </Dialog>
        <Dialog open={openFooterAbout} handler={handleFooterAbout}>
          <DialogHeader className="flex justify-center">About Us</DialogHeader>
          <DialogBody divider className="h-[20rem] overflow-scroll">
            <Typography className="font-normal">
              Dr. Karen Perez and her husband, Juan González, had always dreamt
              of starting their own business in downtown Tijuana, Baja
              California. After years of hard work, extensive education, and
              numerous successful practices, the couple had finally gathered the
              necessary funds to make their dream a reality.
              <br />
              Dr. Perez had always been passionate about dentistry and had
              completed her undergraduate degree in Odontology from Baja
              California State University (UABC). After that, she attended the
              prestigious Health Science Faculty, at Valle de Las Palmas Campus
              (UABC). She then went on to complete a rigorous two-year program
              in ROOT CANAL at the School of Dentistry (CUPIS), where she earned
              her Doctor of Dental Surgery (DDS) degree in Endodontics.
              <br />
              Juan, on the other hand, had a background in business and finance.
              He had earned his undergraduate degree in International Business
              from the San Diego State University (SDSU) and had gone on to
              receive an MS from COLEF Economics School.
              <br />
              Together, the couple had the perfect combination of knowledge and
              skills to start a successful business. They had previously
              researched the market in Tijuana’s heterogeneous market and found
              that there was a high demand for top-quality dental care and
              personal attention services in low earning districts. Meanwhile
              the streets were full of dental ads for new offices opening every
              other day in locations close to downtown and the border. However,
              they had a vision of success over everyone doing the same
              services.
              <br />
              Accordingly, the couple decided to re-open a state-of-the-art
              dental practice, equipped with the latest technology, and staffed
              with highly qualified professionals. They started
              <br />
              to work in a renovation in one of the most crowded, low earning,
              popular locations in Tijuana and invested heavily in the
              renovation of the space. They hired a team of experienced
              architects and designers who created a modern and inviting
              environment, complete with the latest dental equipment and
              technology.
              <br />
              Dr. Perez was especially excited about the technology they were
              able to incorporate into the practice to make possible for the
              dental team to diagnose and treat oral health issues more
              precisely and efficiently than ever before.
              <br />
              The couple also invested in top-of-the-line dental chairs that
              provided maximum comfort for their patients. They had hired a team
              of highly qualified dental hygienists and assistants who were
              trained to provide top-quality care and make every patient feel at
              ease at a comfortable and harmonious space.
              <br />
              The practice was an instant success among family and friends. More
              patients were suddenly impressed by the high-quality care they
              received and the state-of-the-art facilities. Word of mouth spread
              quickly, and the practice soon became known as a top destination
              in Tijuana for anyone in need of top-quality dental care for root
              canal treatments at a fraction of the cost.
              <br />
              Their dedication to their patients and their community continued
              to inspire others, and their practices continued to thrive. Dr.
              Perez and Juan’s successful partnership had not only allowed them
              to build a thriving business, but it had also allowed them to make
              a difference in the lives of countless people.
              <br />
              After extensive research, the couple discovered that many people
              were traveling across the border to Tijuana, Mexico, to receive
              affordable dental care. They knew that they could make a
              difference by opening a new facility in Tijuana and providing the
              same high-quality care and safe practices that they were known for
              in their previous practice.
              <br />
              So, once again, they assembled a team of highly qualified dental
              professionals in downtown Tijuana and began the process of opening
              a new facility. They made sure to invest in the same
              state-of-the-art equipment and technology that they had in their
              previous practice, and they trained their staff on the same safe
              practices and quality standards.
              <br />
              The new facility was an instant success. Patients were thrilled to
              receive the same high-quality care that they had come to expect
              from Dr. Perez and Juan's practice, but at a
              <br />
              fraction of the cost. The Tijuana facility quickly became known as
              a go-to destination for anyone looking for affordable dental care
              without sacrificing quality or safety.
              <br />
              Dr. Perez and Juan were thrilled with the success of their new
              venture, closer to the border, but they knew that there was still
              more work to be done. They were determined to continue to provide
              the highest level of care to all their patients, regardless of
              their ability to pay.
              <br />
              To that end, they established a scholarship program to help fund
              the education of aspiring dental professionals in the United
              States and Mexico. They also worked with local organizations to
              provide free dental care to those in need, both in San Diego and
              Tijuana.
              <br />
              They were grateful for the opportunity to do what they loved every
              day, and they looked forward to many more years of providing
              top-quality dental care to their patients.
              <br />
              But what really set Perez’s practice apart was the successful
              marriage behind it all. Dr. Perez and Juan had been married for
              over 6 years and their successful partnership extended beyond
              their personal relationship. They had a natural rhythm, they
              balanced each other out, and they both understood the importance
              of hard work and dedication to achieve their goals.
              <br />
              Their staff noticed the way they communicated with each other,
              their mutual respect, and the way they worked together to make
              important decisions for the practice. It was evident that their
              successful partnership was the foundation of the practice's
              success.
              <br />
              Dr. Perez and John were thrilled with their success and were
              grateful for the opportunity to serve the people of Tijuana. They
              knew that their success was due to their hard work, dedication,
              and passion for dentistry, and they were determined to continue to
              provide the highest level of care to their patients for many years
              to come.
              <br />
              As Dr. Perez and Juan’s dental practice in Greater Tijuana and San
              Diego continued to thrive, they began to think about ways to
              expand their reach and provide affordable dental care to even more
              patients. They knew that many people in the United States
              struggled to afford high-quality dental care, and they wanted to
              find a way to make their services more accessible.
            </Typography>
          </DialogBody>
          <DialogFooter className="space-x-2">
            <Button variant="outlined" color="red" onClick={handleFooterAbout}>
              close
            </Button>
          </DialogFooter>
        </Dialog>
      </section>
    </main>
  );
}

export default ContactPage;
